import React from "react"
import PropTypes from "prop-types"
import StaffModal from "./StaffModal"
import { FaChevronRight } from "react-icons/fa"
import { GatsbyImage } from "gatsby-plugin-image"

const StaffItem = ({
  person: { name, position, profile, index },
  staffList,
}) => {
  return (
    <div>
      <StaffModal activeIndex={index} list={staffList}>
        <figure className="flex justify-between rounded-lg cursor-pointer group bg-white hover:shadow-xl transition duration-300 ease-out">
          <div className="flex flex-col justify-center p-6 space-y-4">
            <figcaption>
              <p className="text-gray-700 text-xl font-black font-serif">
                {name}
              </p>
              <p className="text-gray-500 mt-1">{position}</p>
            </figcaption>
            <div className="flex items-center space-x-2 font-medium text-base text-gray-500 group-hover:text-forhover">
              <p>暸解更多</p>
              <FaChevronRight />
            </div>
          </div>
          <GatsbyImage
            loading="lazy"
            alt={name}
            image={profile}
            className="w-36 h-36 sm:w-40 sm:h-40 rounded-r-lg object-cover"
          />
        </figure>
      </StaffModal>
    </div>
  )
}

const LocationStaffBlock = ({
  location: { locationName, staff },
  index,
  locationRefs,
  staffList,
}) => {
  const locationStaff = staff.map((person, index) => (
    <StaffItem key={index} person={person} staffList={staffList} />
  ))

  return (
    <div
      key={locationName}
      className={`relative grid lg:grid-cols-2 gap-6 sm:gap-12 ${
        index !== 0 ? "pt-12 sm:pt-16" : ""
      }`}
      ref={locationRefs.current[index]}
    >
      <div id={locationName} className="absolute top-0 -mt-32" />
      {locationStaff}
    </div>
  )
}

const StaffBlock = ({ locationStaff, locationRefs, staffList }) => {
  const staffBlock = locationStaff.map((location, index) => (
    <LocationStaffBlock
      key={index}
      location={location}
      index={index}
      locationRefs={locationRefs}
      staffList={staffList}
    />
  ))

  return <>{staffBlock}</>
}

StaffItem.propTypes = {
  person: PropTypes.object,
  staffList: PropTypes.array,
  index: PropTypes.number,
}

LocationStaffBlock.propTypes = {
  location: PropTypes.object,
  index: PropTypes.number,
  locationRefs: PropTypes.object,
  staffList: PropTypes.array,
}
StaffBlock.propTypes = {
  locationStaff: PropTypes.array,
  locationRefs: PropTypes.object,
  staffList: PropTypes.array,
}

export default StaffBlock
