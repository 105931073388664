import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import Modal from "react-modal"
import { FaArrowLeft, FaArrowRight, FaTimesCircle } from "react-icons/fa"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const StaffModal = ({ activeIndex, children, list }) => {
  // 使用者選取到的index
  let [curIndex, setCurIndex] = useState(0)
  // 選到index的資料
  const [curStaff, setCurStaff] = useState({})
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = async () => {
    await setModalIsOpen(true)
    await setCurIndex(activeIndex)
    await setCurStaff(list[activeIndex])
  }
  const closeModal = async () => {
    await setModalIsOpen(false)
  }
  const next = async () => {
    if (curIndex === list.length - 1) {
      return
    }
    await setCurIndex((curIndex += 1))
    await setCurStaff(list[curIndex])
  }
  const previous = async () => {
    if (curIndex === 0) {
      return
    }
    await setCurIndex((curIndex -= 1))
    await setCurStaff(list[curIndex])
  }

  // 防治背景滑動
  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [modalIsOpen])

  const ModalView = () => {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Staff Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        overlayClassName="flex items-center m-auto px-6 fixed top-0 z-50 bg-black bg-opacity-75 transition-all duration-500 delay-500 ease-out block w-full h-full"
        className="block bg-white p-6 m-auto w-full lg:w-168 rounded-lg max-h-content lg:h-auto overflow-y-auto lg:overflow-hidden transition-all duration-500 delay-500 ease-in-out lg:relative"
      >
        <button
          onClick={closeModal}
          aria-label="Close panel"
          className="text-white absolute lg:fixed top-0 right-0 z-10 text-2xl mt-6 mr-6 cursor-pointer sm:mr-8 sm:ml-8"
        >
          <FaTimesCircle size={24} />
        </button>

        <button
          onClick={next}
          aria-label="Next modal"
          className="w-12 h-12 flex lg:hidden items-center justify-center fixed bottom-0 right-0 mb-6 mr-6 z-10 bg-white text-xl text-gray-500 hover:text-gray-600 rounded-full shadow-md"
        >
          <FaArrowRight size={20} />
        </button>

        <button
          onClick={previous}
          aria-label="Previous modal"
          className="w-12 h-12 flex lg:hidden items-center justify-center fixed bottom-0 right-0 mb-6 mr-28 z-10 bg-white text-xl text-gray-500 hover:text-gray-600 rounded-full shadow-md"
        >
          <FaArrowLeft size={20} />
        </button>

        <div className="space-y-6 sm:space-y-0 sm:flex sm:space-x-6">
          <GatsbyImage
            alt={curStaff?.name}
            image={curStaff?.profile}
            objectFit="cover"
            className="w-full sm:w-[340px] sm:h-64"
          />
          <div className="sm:w-1/2">
            <div className="my-6 sm:mt-0">
              <p className="text-gray-700 text-xl font-black font-serif">
                {curStaff?.name}
              </p>
              <p className="text-gray-500 mt-1">{curStaff?.position}</p>
            </div>
            <ReactMarkdown className="whitespace-pre-line leading-relaxed">
              {curStaff?.education + "\n" + curStaff?.experience}
            </ReactMarkdown>
            <div className="hidden lg:flex h-full items-center justify-center absolute top-0 right-0">
              <button
                onClick={next}
                aria-label="Next modal"
                className="w-40 h-full hidden lg:flex justify-end items-center pr-4 hover:text-gray-500 bg-transparent opacity-0 hover:opacity-100 transform hover:translate-x-2 transition-all ease-out duration-300 arrow-wrapper transparent-right"
              >
                <FaArrowRight size={20} className="arrow" />
              </button>
            </div>
            <div className="hidden lg:flex h-full items-center justify-center absolute top-0 left-0">
              <button
                onClick={previous}
                aria-label="Previous modal"
                className="w-40 h-full hidden lg:flex justify-start items-center pl-4 hover:text-gray-500 bg-transparent opacity-0 hover:opacity-100 transform hover:-translate-x-2 transition-all ease-out duration-300 arrow-wrapper transparent-left"
              >
                <FaArrowLeft size={20} className="arrow" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <>
      <div onClick={openModal}>{children}</div>
      <ModalView curStaff={curStaff} />
    </>
  )
}

StaffModal.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object,
  activeIndex: PropTypes.number,
  list: PropTypes.arrayOf(PropTypes.object),
}

export default StaffModal
