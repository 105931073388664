import { graphql, useStaticQuery } from "gatsby"
import useLocationData from "./useLocationData"
import default_profile from "../images/default_profile.png"

const useLocationStaffData = () => {
  const { locationDict, locations } = useLocationData()

  const data = useStaticQuery(graphql`
    {
      allAirtableStaff(sort: { fields: rowIndex, order: ASC }) {
        nodes {
          data {
            Name
            Experience
            Education
            Slug
            Profile {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 550)
                }
              }
            }
            Position {
              data {
                Name
              }
            }
            Office {
              data {
                Name
              }
            }
          }
        }
      }
    }
  `)
  const staffList = data.allAirtableStaff.nodes.map((node, index) => {
    const {
      data: { Name, Experience, Education, Profile, Position, Office, Slug },
    } = node

    const person = {
      name: Name,
      experience: Experience || "",
      education: Education || "",
      profile:
        Profile.localFiles[0].childImageSharp.gatsbyImageData ||
        default_profile,
      position: Position[0].data.Name,
      office: Office[0].data.Name,
      slug: Slug,
      index: index,
    }
    // 把律師分配到所屬的地區
    locationDict[person.office].push(person)
    return person
  })
  locations.forEach(location => {
    location.staff = locationDict[location.locationName]
  })
  const locationStaff = locations
  return { locationStaff, staffList }
}

export default useLocationStaffData
