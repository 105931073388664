import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import useLandingImage from "../hooks/useLandingImage"
import useMetadata from "../hooks/useMetadata"
import Layout from "../components/Layout"
import PropTypes from "prop-types"
import StaffBlock from "../components/staff/StaffBlock"
import useLocationStaffData from "../hooks/useLocationStaffData"
import useLocationData from "../hooks/useLocationData"
import LocationSlide from "../components/LocationSlide"

const Staff = ({ data: { joinUs, staff }, location }) => {
  let pathname = ""
  if (location) {
    pathname = location.pathname
  }
  const { locationStaff, staffList } = useLocationStaffData()
  const { locations } = useLocationData()
  const [isSticky, setIsSticky] = useState(false)
  const { staff: staffImage, staffOGImage } = useLandingImage()

  const [locationNavIndex, setLocationNavIndex] = useState(0)
  const [locationNavStyle, setLocationNavStyle] = useState({
    width: 0,
    left: 0,
  })
  const locationRefs = useRef(locations.map(() => React.createRef()))
  const currentSubMenuRef = useRef(null)

  const handleScroll = () => {
    let scrollTop = window.pageYOffset
    let currentIndex = 0
    const offset = 250
    const sections = locationRefs.current

    sections.forEach((section, index) => {
      const offsetTop = section.current.offsetTop - offset
      if (scrollTop >= offsetTop) {
        currentIndex = index
      }
    })

    setLocationNavIndex(currentIndex)
    setLocationNavStyle({
      width: currentSubMenuRef.current.offsetWidth,
      left: currentSubMenuRef.current.offsetLeft,
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })
  useEffect(() => {
    const handleStickyScroll = () => {
      if (window.scrollY >= 364) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }

    handleStickyScroll()
    return handleStickyScroll()
  })

  const { siteUrl } = useMetadata()
  const { descriptionImage } = joinUs
  const { title } = staff
  const metaTitle = "喆律同仁"
  const metaDescription = title
  const ogImage = `https:${staffOGImage}`
  const ogImageAlt = metaTitle
  const canonical = `${siteUrl}/staff`

  return (
    <>
      <Layout transParentMenu={false} pathname={pathname}>
        <GatsbySeo
          title={metaTitle}
          description={metaDescription}
          canonical={canonical}
          openGraph={{
            title: metaTitle,
            description: metaDescription,
            url: canonical,
            images: [
              {
                url: ogImage,
                alt: ogImageAlt,
              },
            ],
            type: "website",
          }}
        />
        <div className="mt-nav">
          <div className="relative w-full">
            <div className="absolute inset-0">
              <div className="absolute bg-black w-full h-full opacity-50 z-20" />
              <StaticImage
                className="w-full h-full z-10"
                src="../images/staff_hero.jpeg"
                alt="其他同仁"
                objectFit="cover"
                loading="eager"
                placeholder="blurred"
                imgStyle={{
                  objectPosition: "top center",
                }}
              />
            </div>

            <div className="relative z-30 h-full flex justify-center items-center container px-6 sm:px-8 py-16 lg:py-24">
              <div className="text-white max-w-4xl lg:text-lg">{title}</div>
            </div>
          </div>
        </div>

        <div className="location-lawyer py-16 lg:pb-20 bg-gray-200">
          <div className="sm:container sm:flex sm:mx-auto sm:px-8">
            <div className="phone:sticky phone:top-nav phone:z-20 sm:w-2/5 lg:w-1/4 sm:pr-12">
              <nav
                className={`location-nav bg-gray-200 sticky top-nav z-10 ${
                  isSticky
                    ? "bg-white sm:bg-gray-200 transition duration-300 ease-out"
                    : ""
                }`}
              >
                <div className="phone:container phone:px-6">
                  <ul
                    className={`relative w-full p-0 flex sm:block sm:pt-4 bg-gray-200 ${
                      isSticky
                        ? "bg-white sm:bg-gray-200 transition duration-300 ease-out"
                        : ""
                    }`}
                  >
                    <LocationSlide
                      locations={locations}
                      locationNavIndex={locationNavIndex}
                      currentSubMenuRef={currentSubMenuRef}
                      pageName={"staff"}
                    />
                    <div
                      className="nav-active-bar phone:border-b phone:border-primary phone:absolute phone:bottom-0 phone:left-0 phone:w-full phone:z-10 phone:transition-all phone:duration-300 phone:ease-in phone:w-20"
                      style={locationNavStyle}
                    />
                  </ul>
                </div>
              </nav>
            </div>
            <div className="sm:w-3/5 lg:w-3/4">
              <div className="lawyer-list mt-8 sm:pl-12 sm:mt-0 lg:pl-0">
                <div className="phone:container phone:px-6 divide-y divide-gray-300 grid gap-12 sm:gap-16">
                  <StaffBlock
                    locationStaff={locationStaff}
                    locationRefs={locationRefs}
                    staffList={staffList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="grid lg:grid-cols-2 gap-12 container mx-auto py-16 lg:py-24">*/}
        {/*  <GatsbyImage*/}
        {/*    className="w-full h-auto"*/}
        {/*    image={descriptionImage.gatsbyImageData}*/}
        {/*    alt="喆律團隊"*/}
        {/*    imgStyle={{*/}
        {/*      objectPosition: "top center",*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <div className="flex flex-col justify-center">*/}
        {/*    <ReactMarkdown*/}
        {/*      className="text-gray-700 text-base"*/}
        {/*      rehypePlugins={[rehypeRaw]}*/}
        {/*    >*/}
        {/*      {description.description}*/}
        {/*    </ReactMarkdown>*/}
        {/*    <Link to={"/about"} className="mt-6 text-center lg:text-left">*/}
        {/*      <PrimaryBtn>查看律師團隊</PrimaryBtn>*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Layout>
    </>
  )
}

Staff.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default Staff

export const pageQuery = graphql`
  query {
    joinUs: contentfulJoinUs(slug: { eq: "join-us" }) {
      descriptionImage {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    staff: contentfulStaff(slug: { eq: "staff" }) {
      title
      description {
        description
      }
    }
  }
`
